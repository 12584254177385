import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SharedService } from './shared/services/shared.service';
import { UPUtilityService } from 'up';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private upUtilityService: UPUtilityService,
    private translate: TranslateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isAuthorized = this.upUtilityService.hasAuthorizedAccess(
      route?.data,
      this.sharedService.getRole(),
      this.sharedService.getLtiaAction()
    );
    if (!isAuthorized) {
      this.translate
        .get(['GENERIC.UNAUTHORIZED.PAGE_TITLE', 'GENERIC.PAGE_TITLE'])
        .pipe()
        .subscribe({
          next: (translatedText) => {
            const unAuthorizedpageTitle =
              translatedText['GENERIC.UNAUTHORIZED.PAGE_TITLE'];
            const pageTitle = translatedText['GENERIC.PAGE_TITLE'];
            this.router.navigate(['/unauthorized'], {
              state: {
                deleteUrl: `${environment.apiUrl}/go`,
                pageTitle: unAuthorizedpageTitle + pageTitle,
              },
            });
          },
        });
    }
    return isAuthorized;
  }
}
