import { __decorate } from "tslib";
import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';
export class MhIcon extends LitElement {
  constructor(svg, tagName) {
    super();
    /**
     * Provide an aria-label for this icon if that is relevant
     */
    this.ariaLabel = '';
    /**
     * provide a Bootstrap size abbreviation or valid css value5
     *
     */
    this.size = 'md';
    /**
     * a list of predefined sizes that can be applied via keyword
     */
    this.allSizes = {
      'default': '1.25em',
      'xs': '12px',
      'sm': '16px',
      'md': '20px',
      'lg': '24px',
      'xl': '32px',
      'xxl': '40px'
    };
    /**
     * the valid css value that will be applied to the SVG's height and width
     * private
     */
    this.sizeString = `1.25em`;
    this.size = 'default';
    this.svg = svg;
  }
  attributeChangedCallback(name, oldVal, newVal) {
    if (name === 'size' && newVal !== null) {
      this.size = newVal;
      this.setSizeString();
    }
    super.attributeChangedCallback(name, oldVal, newVal);
  }
  /**
   * determine if the size value is valid by using it on a placeholder node
   * @example
   *   isValidCssSizeValue('13px') // true
   *   isValidCssSizeValue('bad value') // false
   */
  isValidCssSizeValue(value) {
    const s = new Option().style;
    s.width = value;
    // if value is not valid, it will return an empty string
    return s.width !== '';
  }
  /**
   * set size value based on keyword or valid css value
   *
   */
  setSizeString() {
    if (this.size in this.allSizes) {
      this.sizeString = this.allSizes[this.size];
    } else if (this.isValidCssSizeValue(this.size)) {
      this.sizeString = this.size.trim().toLowerCase();
    } else {
      this.sizeString = this.allSizes['default'];
    }
  }
  /**
   * this function may come in handy if we need to implement svg use
   *
   */
  iconCountInDOM() {
    return document.getElementsByTagName(this.tagName) ? document.getElementsByTagName(this.tagName).length : 0;
  }
  /**
   * lit lifecycle hook
   * https://lit.dev/docs/v1/components/templates/#renderroot
   */
  createRenderRoot() {
    /**
     * Render template without shadow DOM. Note that shadow DOM features like
     * encapsulated CSS and slots are unavailable.
     */
    return this;
  }
  render() {
    const domParser = new DOMParser();
    const svgDocument = domParser.parseFromString(this.svg, 'image/svg+xml');
    const element = svgDocument.getElementsByTagName('svg')[0];
    element.setAttribute('height', this.sizeString);
    element.setAttribute('width', this.sizeString);
    if (this.ariaLabel) {
      element.setAttribute('aria-label', this.ariaLabel);
      element.setAttribute('role', 'img');
    } else {
      element.setAttribute('aria-hidden', 'true');
    }
    return element;
  }
}
__decorate([property({
  type: String
})], MhIcon.prototype, "ariaLabel", void 0);
__decorate([property({
  type: String
})], MhIcon.prototype, "size", void 0);
__decorate([property({
  attribute: false
})], MhIcon.prototype, "allSizes", void 0);
__decorate([property({
  attribute: false
})], MhIcon.prototype, "sizeString", void 0);
